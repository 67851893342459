a {
  text-decoration: none !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* img {
  width: 100%;
} */

/* .emptyImage {
  background-position: center;
  background-size: contain;
  height: 10rem;
  text-align: center;
} */

.mainImgInput {
  /* max-height: 10rem; */
  width: 50%;
}

/* .landing {
  position: relative;
  background: url("./img/reef.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -24px;
  margin-bottom: -50px;
} */

.landing-inner {
  padding-top: 80px;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::-webkit-input-placeholder {
  color: #bbb !important;
}

.form-control::placeholder {
  color: #bbb !important;
}

/* Tooltip container */
.CoralNames_tooltip__27AqT {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
}

/* Tooltip text */
.CoralNames_tooltip__27AqT .CoralNames_tooltiptext__UXgQw {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.CoralNames_tooltip__27AqT:hover .CoralNames_tooltiptext__UXgQw {
  transition-delay: 1s;
  visibility: visible;
}

.AllCoral_nameRow__1S-s0 {
  padding-right: 0;
}

.AllCoral_nameCardTitle__2t_h_ {
  text-align: start;
  margin: 0 !important;
}

.AllCoral_footerRow__2V4MJ {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important;
}

.AllCoral_buttonRow__B2j7q {
  padding: 0.5rem;
  padding-bottom: 0;
  padding-top: 0;
  /* margin-left: 0.5rem; */
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center !important;
}

.AllCoral_commentsIcon__QmHfw {
  background-repeat: no-repeat;
  background-size: cover;
}

.AllCoral_commentsNumber__1tov1 {
  text-align: center;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  font-size: 0.65rem;
}

.AllCoral_Modal__1kun3 {
  width: 100% !important;
  /* margin-left: 10% !important;
      margin-right: 10% !important; */
}

.AllCoral_Backdrop__2qwb7 {
  opacity: 0.8 !important;
}

.AllCoral_CropArea__1K0Ts {
  height: 100% !important;
}

.AllCoral_MadeBox__1BaUH:hover {
  cursor: pointer;
}

.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
}

#map {
  position: relative;
  width: 100%;
  height: 100vh;
}

.demo-iconshtml {
  font-size: 62.5%;
}

.demo-icons body {
  font-size: 1.6rem;
  font-family: sans-serif;
  color: #333333;
  background: white;
}

.demo-icons a {
  color: #608CEE;
  text-decoration: none;
}

.demo-icons header {
  text-align: center;
  padding: 100px 0 0;
}

.demo-icons header h1 {
  font-size: 2.8rem;
}

.demo-icons header p {
  font-size: 1.4rem;
  margin-top: 1em;
}

.demo-icons header a:hover {
  text-decoration: underline;
}

.demo-icons .nc-icon {
  font-size: 34px;
}

.demo-icons section h2 {
  border-bottom: 1px solid #e2e2e2;
  padding: 0 0 1em .2em;
  margin-bottom: 1em;
}

.demo-icons ul {
  padding-left: 0;
}

.demo-icons ul::after {
  clear: both;
  content: "";
  display: table;
}

.demo-icons ul li {
  width: 20%;
  float: left;
  padding: 16px 0;
  text-align: center;
  border-radius: .25em;
  transition: background 0.2s;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
}

.demo-icons ul li:hover {
  background: #f4f4f4;
}

.demo-icons ul p,
.demo-icons ul em,
.demo-icons ul input {
  display: inline-block;
  font-size: 1rem;
  color: #999999;
  -webkit-user-select: auto;
  user-select: auto;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.demo-icons ul p {
  padding: 20px 0 0;
  font-size: 12px;
  margin: 0;
}

.demo-icons ul p::selection,
.demo-icons ul em::selection {
  background: #608CEE;
  color: #efefef;
}

.demo-icons ul em {
  font-size: 12px;
}

.demo-icons ul em::before {
  content: '[';
}

.demo-icons ul em::after {
  content: ']';
}

.demo-icons ul input {
  text-align: center;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: none;
}
