.nameRow {
  padding-right: 0;
}

.nameCardTitle {
  text-align: start;
  margin: 0 !important;
}

.footerRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important;
}

.buttonRow {
  padding: 0.5rem;
  padding-bottom: 0;
  padding-top: 0;
  /* margin-left: 0.5rem; */
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center !important;
}

.commentsIcon {
  background-repeat: no-repeat;
  background-size: cover;
}

.commentsNumber {
  text-align: center;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  font-size: 0.65rem;
}

.Modal {
  width: 100% !important;
  /* margin-left: 10% !important;
      margin-right: 10% !important; */
}

.Backdrop {
  opacity: 0.8 !important;
}

.CropArea {
  height: 100% !important;
}

.MadeBox:hover {
  cursor: pointer;
}
